<template>
  <div class="checkoutDlg">
    
    <a-icon style="top: 30px;right:30px;position: absolute;" type="close" v-on:click="onClosePay" :style="{ fontSize: '30px', color:'#555555' }"/> 
    <div class="checkoutDlgMain">
      <div class="checkoutDlgDiv1">
        <div class="checkoutDlgLogoDiv">
          <img class="logoImageCheckout" :src="'/images/logo_2_brown.png'"/>
          <!-- <div class="logoTitleCheckout">Vue Collection</div> -->
        </div>
        
        <div class="checkoutDlgSummaryDiv">
          <div class="checkoutDlgDetail1">Billing Summary</div>
          <div class="checkoutDlgSummaryDetailDiv">
              <div class="checkoutDlgHotelDetail2">Room subtotal</div>
              <div class="checkoutHotelDetail3 room-subtotal">{{subPrice > 0 ? currencySign + " " + addZeroes(Math.round(subPrice * 100) / 100) : "Request Basis"}}</div>
          </div>
          <div class="enhanceSummaryLineDiv"></div>

          <div class="checkoutDlgDetail1" style="margin-top:20px;">Mandatory Service & Fees:</div>
          
          <div class="checkoutDlgSummaryDetailDiv">
              <div class="checkoutDlgHotelDetail2">$6 Green Tax </div>
              <div class="checkoutDlgHotelDetail3">
                  {{currencySign}}{{addZeroes(Math.round(greenTax * currency * 100) / 100)}}
              </div>
          </div>
          <div class="enhanceSummaryLineDiv"></div>
          
          <div class="checkoutDlgSummaryDetailDiv">
              <div class="checkoutDlgHotelDetail2">GST & Services charge </div>
              <div class="checkoutDlgHotelDetail3">
                  {{currencySign}}{{addZeroes(Math.round(gstService * currency * 100) / 100)}}
              </div>
          </div>
          <div class="enhanceSummaryLineDiv"></div>
          
          <div class="checkoutDlgSummaryDetailDiv">
              <div class="checkoutDlgHotelDetail2">Extra Adult Charge</div>
              <div class="checkoutDlgHotelDetail3">
                  {{currencySign}}{{addZeroes(Math.round(extraAdult * currency * 100) / 100)}}
              </div>
          </div>
          <div class="enhanceSummaryLineDiv"></div>
          
          <div class="checkoutDlgSummaryDetailDiv">
              <div class="checkoutDlgHotelDetail2">Extra Child Charge</div>
              <div class="checkoutDlgHotelDetail3">
                  {{currencySign}}{{addZeroes(Math.round(extraChild * currency * 100) / 100)}}
              </div>
          </div>
          <div class="enhanceSummaryLineDiv"></div>

          <div ref="add-values" class="checkoutDlgDetail1" style="margin-top:20px;">Add-ons</div>
          
          <div class="checkoutDlgSummaryDetailDiv">
              <div class="checkoutDlgHotelDetail2">Transfers</div>
              <div class="checkoutDlgHotelDetail3">
                  {{currencySign}}{{addZeroes(Math.round(transferTotalPrice * currency * 100) / 100)}}
              </div>
          </div>
          <div class="enhanceSummaryLineDiv"></div>
          
          <div class="checkoutDlgSummaryDetailDiv">
              <div class="checkoutDlgHotelDetail2">Meal Plan</div>
              <div class="checkoutDlgHotelDetail3">
                  {{currencySign}}{{addZeroes(Math.round(mealplanTotalPrice * currency * 100) / 100)}}
              </div>
          </div>
          <div class="enhanceSummaryLineDiv"></div>
          
          <!-- <div class="checkoutDlgSummaryDetailDiv">
              <div class="checkoutDlgHotelDetail2">Festive mandatory supplement</div>
              <div class="checkoutDlgHotelDetail3">
                  {{currencySign}}{{addZeroes(Math.round(festiveTotalPrice * currency * 100) / 100)}}
              </div>
          </div> -->
          <div class="enhanceSummaryLineDiv"></div>
          
          <div v-if="honeymoonInfoSelected.length > 0" style="width:100%;">
              <div class="checkoutDlgSummaryDetailDiv">
                  <div class="checkoutDlgHotelDetail2" >Honeymoon</div>
                  <div class="checkoutDlgHotelDetail3">
                      <div v-for="_info in honeymoonInfoSelected" :key="_info._id">
                          {{_info.honeytitle}}
                      </div>
                  </div>
              </div>
              <div class="enhanceSummaryLineDiv"></div>
          </div>
          <div class="checkoutHotelDetail5">Final Total: {{grossTotalPrice > 0 ? currencySign + " " + addZeroes(Math.round(grossTotalPrice * currency * 100) / 100) : "Request Basis"}}</div>
              
                  
        </div>
      </div>
      <div class="checkoutDlgDiv2">
        
        <div class="checkoutDlgLine"></div>
        <div class="checkoutDlgMainText">
          Booking is not confirmed until our concierge team has sent you a confirmation with a booking reference number within 24 hours via email.
        </div>
        <!-- <div style="display:flex;color: #AAAAAA;padding-top:30px;padding-bottom:30px;">
          <div style="width:33%;height:1px;margin:auto;background-color: #AAAAAA;"></div>
          <div style="width:33%;margin:auto">Or pay with card</div>
          <div style="width:33%;height:1px;margin:auto;background-color: #AAAAAA;"></div>
        </div>
        
        <div style="font-size: 28px;text-align: center;margin-top:0px;line-height: 1.1;">
          Total
        </div>
        <div style="font-size: 24px;text-align: center;font-weight: 600;color:#111111;margin-top:0px">
          {{currencySign}}{{addZeroes(Math.round(grossTotalPrice * currency * 100) / 100)}}
        </div>
        <div style="font-size: 14px;text-align: right;font-weight: 500;color:#111111;margin-top:0px;text-decoration-line: underline;cursor: pointer;" @click="clickPayDirectTransfer">
          Pay direct bank transfer
        </div> -->
        
        <div class="checkoutDlgCardInfoDiv" >
          <div class="checkoutDlgCardInfoTitle">Card Information</div>
          <div class="checkoutDlgCardInfo">
            <div class="checkoutDlgCardInfoSub1">
              <a-input v-model="cardNumber" class="checkoutDlgCardInfoSubInput" placeholder="1234 1234 1234 1234" @change="changeCardNumber"/>
              <div class="checkoutDlgCardInfoSubDiv">
                <img class="cardIconImage" :src="`/images/${cardIcon}`"/>
              </div>
            </div>
            <div style="display:flex">
              <a-input v-model="date" class="checkoutDlgCardInfoDate" placeholder="MM / YY" v-cardformat:formatCardExpiry/>
              <a-input v-model="cvc" class="checkoutDlgCardInfoCvc" placeholder="CVC" v-cardformat:formatCardCVC/>
            </div>
          </div>
        </div>
        <div class="checkoutDlgCardNameDiv">
          <div class="checkoutDlgCardNameTitle">Name on card</div>
          <a-input v-model="name" class="checkoutDlgCardNameInput"/>
        </div>
        <div class="checkoutDlgCardCountryDiv">
          <div class="checkoutDlgCardCountryTitle">Country or region</div>
          <div class="checkoutCountryDiv">
            <a-auto-complete
                :data-source="countryList"
                class="checkoutCountryAuto"
                
                placeholder="Country"
                v-model="country"
                @select="onSelectCountry"
                @blur="onBlurCountry"
                :filter-option="filterOption"
            /> 
            <a-input v-model="zip" class="checkoutCountryInput" placeholder="ZIP"/>

          </div>
        </div>
        <div class="checkoutDlgDirectDiv" @click="clickPayDirectTransfer">
          Pay direct bank transfer{{permissionLevel > 4 ? '(14% commission)' : ''}}
        </div>
        <div class="homeFactBtnDiv">
          <div class="payingBtn"
            :class="{ dis: paying }" @click="onClickPay">
            {{paying ? "Paying..." : "Pay"}}
          </div>
          <div class="closeBtn"
            :class="{ dis: paying }" @click="onClosePay">
            Cancel
          </div>

        </div>
        <div class="checkoutDlgBottomText">
          A payment authorization will be placed on your card. 
          After a confirmation is sent to you, 
          we will charge a 10% deposit of your total booking. 
          The remaining payment will be charged 7 days prior to the penalty period per
          <a class="checkoutDlgBottomTermsText" @click="gotoTerms">terms & conditions.</a>.
        </div>
        
        <a-modal
            class="InclusionDiv"
            v-model="visibleTerms"
            title=""
            :footer="null"
            :closable="true"
            :width="$mq === 'lg' || $mq === 'md' ? '800px' : ''"
            
        >
            <div style="width:100%">
                <div style="background-color:var(--primary-sub-title-color);color:white;font-size: 28px;text-align: center;width:100%">{{visibleTermsTitle}}</div>
                <div style="white-space: pre-wrap;color:black;padding: 20px;line-height: 2;font-size: 15px;font-family: Raleway;width:100%">{{termsText}}</div>
            </div>
        </a-modal>

      </div>
    </div> 
    
    <div class="checkoutDlgContentDiv">
        <div class="checkoutDlgContenttext1">
          With our compliments
        </div>
        <div class="checkoutDlgContenttext2">
          A personal concierge will be assigned to you...
        </div>
        <div class="checkoutDlgContentTextDiv">
          <div class="checkoutDlgContentTextDiv1">
            To ensure your room is 100% secured.
          </div>
          
          <div class="checkoutDlgContentTextDiv2">
            To ensure your arrival & departure transfers are arranged based on your international flights.
          </div>

          <div class="checkoutDlgContentTextDiv3">
            Notify you of any updates & documents to be completed upon entry/exit for Collection.
          </div>  
        </div>
    </div>
  </div>
</template>

<script>

import { loadStripe } from "@stripe/stripe-js";
import { api } from '../helpers/Helpers';
import { common } from '../helpers/Common';
const countryList= ['US', 'RSS', 'CH'];
export default {
    data() {
        return {
          countryList,

          affiliateEmail: 'example@gmail.com',
          affiliateFname: 'Mike',

          logoImage:'images/logo1.png',
          logoName:'MALDIVES1',
          country:'',
          zip:'',
          name:'',
          date:'',
          cvc:'',
          cardNumber:'',
          cardIcon:'card_no.png',
          correctCard:false,
          
          currency:1.0,
          currencyName:'USD',
          currencySign:'$',

          stripe: null,
          paymentMethodReq: null,
          canApplePay: false,

          
          termsText: '',
          visibleTermsTitle: '',
          visibleTerms: false,
        };
    },
    components: {
      
    },
    
    props: {
        permissionLevel: Number,
        grossTotalPrice: Number,
        hotelId: String,
        termsconditions: String,
        paying: Boolean,
        bookingReqId: String,
        mainEmail: String,
        mainPhone: String,
        subPrice: Number,
        greenTax: Number,
        gstService: Number,
        extraAdult: Number,
        extraChild: Number,
        transferTotalPrice: Number,
        mealplanTotalPrice: Number,
        festiveTotalPrice: Number,
        honeymoonInfoSelected: [],
    },
    mounted(){
    },
    created: function () {      
      this.currency = this.$store.state.selectedCurrency.rate;
      this.currencyName = this.$store.state.selectedCurrency.name;
      this.currencySign = this.$store.state.selectedCurrency.sign;
			this.getLogo();
      this.countryList = common.getCountryList();

      this.correctCard = false;

      this.initStripe();
      window.scrollTo(0,0);

      
    },
    methods: {
      
      addZeroes(num){
          let textNum = num.toString();
          let decimalResult = Number(textNum).toFixed(Math.max(textNum.split('.')[1]?.length, 2) || 2);
          let thousand = decimalResult.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          return thousand;
      },
      initStripe:async function(){
        this.stripe = await loadStripe('pk_live_lC6hzigLgulKkvIozhW3AiHo00hsAzUryW');

        this.paymentMethodReq = this.stripe.paymentRequest({
          currency: this.currencyName.toLowerCase(),
          country:'US',
          requestPayerName:true,
          requestPayerEmail:true,
          total:{
            label: 'Booking Pay',
            amount: Math.round(this.grossTotalPrice * 100),
          }
        });
        this.paymentMethodReq.canMakePayment().then((result) => {
          if (result){
            this.canApplePay = true;
          }
          else{
            this.canApplePay = false;
          }
        });
        this.paymentMethodReq.on('paymentmethod', async function(event) {
              
          
          const response = await api.createPrice(Math.round(this.grossTotalPrice * 100));
          const { secret, id } = await response.json();
          // event.paymentMethod is available
          
    // eslint-disable-next-line no-unused-vars
          const { error } = await this.stripe.confirmCardPayment(secret, {
            payment_method: event.paymentMethod.id
          });
          
          this.$emit('onClickApplePay', id);
          return;
        });
      },
      onClickApplePay(){
        if (!this.canApplePay)
          return;
        this.paymentMethodReq.show();
      },
			getLogo:async function(){
				const res = await api.getLogoInfo();
				if (res.length > 0){
					this.logoImage = `${res[0].logoimage}`;
					this.logoName = res[0].logoname;
				}
			},
      gotoTerms(){

        this.termsText = this.termsconditions;
        this.visibleTermsTitle = 'TERMS & CONDITIONS';
        this.visibleTerms = true;

        // let routeData = this.$router.resolve({ path: '/termscondition/', query: {
          // textDate:"sdasadassssssssssss",
          // hotelId:this.hotelId
        // }});
        // window.open(routeData.href, '_blank');

				// window.open("/termscondition","_blank");// "_blank"
      },
      
    // eslint-disable-next-line no-unused-vars
      onSelectCountry(value) {
      }, 
      onBlurCountry() {
          if (!this.countryList.includes(this.country))
              this.country = '';
      },
        
      filterOption(input, option) {
          return (
              option.componentOptions.children[0].text.toUpperCase().indexOf(input.toUpperCase()) >= 0
          );
      },

      clickPayDirectTransfer:async function(){
        // const res = await api.sendPayDirect(_id);
        // if (res == ''){
        //   console.log("clickPayDirectTransfer ==== ", res);
          this.$emit('onClickPayDirect');
          this.triggerTrackingPixel();
        // }
      },

      onClosePay(){
        this.$emit('close')
      },
      onClickPay(){
        if (this.cardNumber == ''){
          this.$message.error('Please input card number.');
          return;
        }
        if (!this.correctCard){
          this.$message.error('Please input correct card number.');
          return;
        }
        if (this.date == ''){
          this.$message.error('Please input expire date.');
          return;
        }
        if (this.cvc == ''){
          this.$message.error('Please input cvc.');
          return;
        }
        if (this.zip == ''){
          this.$message.error('Please input zip.');
          return;
        }
        
        let regexNumber = /^[0-9]+$/;
        if (!regexNumber.test(this.zip.toLowerCase())){
            this.$message.error('Please check zip.');
            return
        }

        let cardInfo = {
          country:this.country,
          zip:this.zip,
          name:this.name,
          date:this.date,
          cvc:this.cvc,
          cardNumber:this.cardNumber,
          price:this.grossTotalPrice
        }
        // this.paying = true;
        this.$emit('onClickPay', cardInfo)
        this.triggerTrackingPixel();
      },
      triggerTrackingPixel() {
        // Tracking script integration
        const script = document.createElement('script');
        script.innerHTML = `
            ir('track', {
                orderID: ${this.bookingReqId},
                event: 'sale',
                fname: ${this.affiliateFname},
                email: ${this.mainEmail},
                mobile: ${this.mainPhone},
                purchaseValue: ${this.grossTotalPrice},
                order_custom_val: ''
            });
        `;
        document.head.appendChild(script);
      },
      checkCreditCard(cardnumber) {
  
        //Error messages
        const ccErrors = [];
        ccErrors [0] = "Unknown card type";
        ccErrors [1] = "No card number provided";
        ccErrors [2] = "Credit card number is in invalid format";
        ccErrors [3] = "Credit card number is invalid";
        ccErrors [4] = "Credit card number has an inappropriate number of digits";
        ccErrors [5] = "Warning! This credit card number is associated with a scam attempt";
        
        //Response format
        const response = (success, message = null, type = null) => ({
          message,
          success,
          type
        });
          
        // Define the cards we support. You may add additional card types as follows.
        
        //  Name:         As in the selection box of the form - must be same as user's
        //  Length:       List of possible valid lengths of the card number for the card
        //  prefixes:     List of possible prefixes for the card
        //  checkdigit:   Boolean to say whether there is a check digit
        const cards = [];
        cards [0] = {name: "Visa", 
                    length: "13,16", 
                    prefixes: "4",
                    checkdigit: true};
        cards [1] = {name: "MasterCard", 
                    length: "16", 
                    prefixes: "51,52,53,54,55",
                    checkdigit: true};
        cards [2] = {name: "DinersClub", 
                    length: "14,16", 
                    prefixes: "36,38,54,55",
                    checkdigit: true};
        cards [3] = {name: "CarteBlanche", 
                    length: "14", 
                    prefixes: "300,301,302,303,304,305",
                    checkdigit: true};
        cards [4] = {name: "AmEx", 
                    length: "15", 
                    prefixes: "34,37",
                    checkdigit: true};
        cards [5] = {name: "Discover", 
                    length: "16", 
                    prefixes: "6011,622,64,65",
                    checkdigit: true};
        cards [6] = {name: "JCB", 
                    length: "16", 
                    prefixes: "35",
                    checkdigit: true};
        cards [7] = {name: "enRoute", 
                    length: "15", 
                    prefixes: "2014,2149",
                    checkdigit: true};
        cards [8] = {name: "Solo", 
                    length: "16,18,19", 
                    prefixes: "6334,6767",
                    checkdigit: true};
        cards [9] = {name: "Switch", 
                    length: "16,18,19", 
                    prefixes: "4903,4905,4911,4936,564182,633110,6333,6759",
                    checkdigit: true};
        cards [10] = {name: "Maestro", 
                    length: "12,13,14,15,16,18,19", 
                    prefixes: "5018,5020,5038,6304,6759,6761,6762,6763",
                    checkdigit: true};
        cards [11] = {name: "VisaElectron", 
                    length: "16", 
                    prefixes: "4026,417500,4508,4844,4913,4917",
                    checkdigit: true};
        cards [12] = {name: "LaserCard", 
                    length: "16,17,18,19", 
                    prefixes: "6304,6706,6771,6709",
                    checkdigit: true};
        
        // Ensure that the user has provided a credit card number
        if (cardnumber.length == 0)  {
          return response(false, ccErrors[1]);
        }
          
        // Now remove any spaces from the credit card number
        // Update this if there are any other special characters like -
        cardnumber = cardnumber.replace (/\s/g, "");
        
        // Validate the format of the credit card
        // luhn's algorithm
        if(!this.validateCardNumber(cardnumber)){
          return response(false, ccErrors[2]);
        }
      
        // Check it's not a spam number
        if (cardnumber == '5490997771092064') { 
          return response(false, ccErrors[5]);
        }

        // The following are the card-specific checks we undertake.
        let lengthValid = false;
        let prefixValid = false; 
        let cardCompany = "";
        
        // Check if card belongs to any organization
        for(let i = 0; i < cards.length; i++){
          const prefix = cards[i].prefixes.split(",");
          
          for (let j = 0; j < prefix.length; j++) {
            const exp = new RegExp ("^" + prefix[j]);
            if (exp.test (cardnumber)) {
              prefixValid = true;
            }
          }
          
          if(prefixValid){
            const lengths = cards[i].length.split(",");
            // Now see if its of valid length;
            for (let j=0; j < lengths.length; j++) {
              if (cardnumber.length == lengths[j]) {
                lengthValid = true;
              }
            }
          }
          
          if(lengthValid && prefixValid){
            cardCompany = cards[i].name;
            return response(true, null, cardCompany);
          }  
        }
        
        // If it isn't a valid prefix there's no point at looking at the length
        if (!prefixValid) {
          return response(false, ccErrors[3]);
        }
        
        // See if all is OK by seeing if the length was valid
        if (!lengthValid) {
          return response(false, ccErrors[4]);
        }
        // The credit card is in the required format.
        return response(true, null, cardCompany);
      },
      validateCardNumber(number){
          //Check if the number contains only numeric value  
          //and is of between 13 to 19 digits
          const regex = new RegExp("^[0-9]{13,19}$");
          if (!regex.test(number)){
              return false;
          }
        
          return this.luhnCheck(number);
      },

      luhnCheck(val) {
          let checksum = 0; // running checksum total
          let j = 1; // takes value of 1 or 2

          // Process each digit one by one starting from the last
          for (let i = val.length - 1; i >= 0; i--) {
            let calc = 0;
            // Extract the next digit and multiply by 1 or 2 on alternative digits.
            calc = Number(val.charAt(i)) * j;

            // If the result is in two digits add 1 to the checksum total
            if (calc > 9) {
              checksum = checksum + 1;
              calc = calc - 10;
            }

            // Add the units element to the checksum total
            checksum = checksum + calc;

            // Switch the value of j
            if (j == 1) {
              j = 2;
            } else {
              j = 1;
            }
          }
        
          //Check if it is divisible by 10 or not.
          return (checksum % 10) == 0;
      },
      getCardIcon(_iconType = 'noCard'){
        var resIcon = '';
        if (_iconType == "Visa")
          resIcon = 'card_visa.png';
        else if (_iconType == "MasterCard")
          resIcon = 'card_master.png';
        else if (_iconType == "DinersClub")
          resIcon = 'card_dinersclub.png';
        else if (_iconType == "AmEx")
          resIcon = 'card_amex.png';
        else if (_iconType == "Discover")
          resIcon = 'card_discover.png';
        else if (_iconType == "Solo")
          resIcon = 'card_solo.png';
        else if (_iconType == "Switch")
          resIcon = 'card_switch.png';
        else if (_iconType == "Maestro")
          resIcon = 'card_maestro.png';
        else if (_iconType == "VisaElectron")
          resIcon = 'card_visaelectro.png';
        else if (_iconType == "noCard")
          resIcon = 'card_no.png';
        else
          resIcon = 'card_visaelectro.png';
        return resIcon;
      },
      
    // eslint-disable-next-line no-unused-vars
      changeCardNumber(val){
        var formattedCardNumber = this.cardNumber.replace(/[^\d]/g, "");
        formattedCardNumber = formattedCardNumber.substring(0, 16);
      
        var cardNumberSections = formattedCardNumber.match(/\d{1,4}/g);
        if (cardNumberSections !== null) {
            formattedCardNumber = cardNumberSections.join(' ');	
        }
        
        if (this.cardNumber !== formattedCardNumber) {
            this.cardNumber = formattedCardNumber;
        }
    
        const resCheck = this.checkCreditCard(this.cardNumber);
        if (resCheck.success){
          this.cardIcon = this.getCardIcon(resCheck.type);
          this.correctCard = true;
        }
        else{
          this.cardIcon = this.getCardIcon('noCard');
          this.correctCard = false;

        }

      }
    },
}
</script>
<style>
.form-control{
  border-radius: 0;
}
.cardIconImage{
    width:30px;
    height:28px;
    float: left;
    margin-top: auto;
    margin-bottom: auto;
    margin-right:3px;
    cursor: pointer;
    object-fit: contain;
}
</style>